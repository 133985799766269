<template>
  <div>
    <p class="setup-sublabel" v-if="!isIRLSession">Your active camera</p>

    <Camera
      ref="camera"
      v-if="canShowVideo && !isIRLSession"
      :image="!!file"
      :camera="camera"
      :cameras="cameras"
      @ready="videoRef = $event"
      @error="e => (retry = !!e)"
      @working="e => (working = !!e)"
      @onCameraChanged="file = null"
    />

    <div class="row justify-center mt-3">
      <CanvasImage v-if="isIRLSession" width="220" height="220" />
    </div>

    <template v-if="retry">
      <div class="setup-check-label">Try again?</div>
      <div class="control-buttons">
        <button class="weve-btn weve-btn--default" @click="onRetry()">
          Yes
        </button>
      </div>
    </template>

    <template v-else>
      <div class="setup-check-label" :class="working ? 'hidden' : null">
        {{ checkLabel }}
        <VueCountdown
          v-if="counting"
          :time="3000"
          @end="onCountdownEnd"
          v-slot="{ totalSeconds }"
        >
          &nbsp; {{ totalSeconds }}
        </VueCountdown>
      </div>

      <div class="control-buttons" :class="working ? 'hidden' : null">
        <template v-if="!isCameraWorking">
          <button class="weve-btn weve-btn--outline" @click="showHelpMessage">
            No
          </button>
          <button
            class="weve-btn weve-btn--default"
            @click="openAvatarUploader"
          >
            Yes
          </button>
        </template>

        <template v-if="!isAvatar && isCameraWorking">
          <button
            class="weve-btn weve-btn--outline"
            :class="{ 'weve-btn--hidden': isWaitingForPhoto }"
            :disabled="isWaitingForPhoto"
            @click="uploadPhoto"
          >
            {{ isIRLSession ? "Take Photo" : "Upload" }}
          </button>
          <button
            v-if="!isIRLSession"
            class="weve-btn weve-btn--default"
            :class="{ 'weve-btn--hidden': isWaitingForPhoto }"
            :disabled="isWaitingForPhoto"
            @click="makePhoto"
          >
            Take Photo
          </button>
        </template>

        <template v-if="isAvatar">
          <button
            class="weve-btn weve-btn--outline"
            :class="{ 'weve-btn--hidden': uploading }"
            @click="resetAvatar"
          >
            No
          </button>
          <button
            class="weve-btn weve-btn--default"
            :class="{ 'weve-btn--hidden': uploading }"
            @click="goToAudioCheck"
          >
            Yes
          </button>
        </template>
      </div>
    </template>

    <input
      type="file"
      ref="file"
      @change="previewFile"
      style="display: none"
      accept="image/x-png,image/gif,image/jpeg"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import VueCountdown from "@chenfengyuan/vue-countdown"

import uniqid from "uniqid"
import { uploadMedia } from "@/services/storage.service"
import Camera from "@/components/GroupTeams/Common/AudioVideoSettings/Camera"
import useCanvasImage from "@/components/GroupTeams/Common/AudioVideoSettings/useCanvasImage"
import CanvasImage from "@/components/GroupTeams/Common/AudioVideoSettings/CanvasImage.vue"

const CHECK_IMG = require("@/assets/login/check.svg")
const CLOSE_IMG = require("@/assets/login/close.svg")
const WARNING_IMG = require("@/assets/login/warning.svg")

export default {
  name: "VideoCard",

  components: {
    CanvasImage,
    Camera,
    VueCountdown
  },

  props: ["setTitle", "setStep"],

  setup() {
    const { imageFromElement } = useCanvasImage()
    return { imageFromElement }
  },

  data() {
    return {
      counting: false,
      working: true,
      retry: false,
      isCameraWorking: false,
      checkLabel: "Can you see yourself?",
      file: null,
      isAvatar: false,
      videoError: false,
      checkImg: CHECK_IMG,
      closeImg: CLOSE_IMG,
      warningImg: WARNING_IMG,
      intervalId: null,
      uploading: false,
      bufferInterval: 0,
      isWaitingForPhoto: false,
      videoRef: null
    }
  },

  computed: {
    ...mapGetters("auth", ["user", "token", "isIRLSession"]),
    canShowVideo() {
      return Boolean(this.camera && this.cameras?.length)
    },
    camera() {
      return this.$store.state.twilio.camera
    },
    cameras() {
      return this.$store.state.twilio.cameras
    }
  },

  watch: {
    isCameraWorking(val) {
      if (val) {
        this.checkLabel = "Take or upload your game photo"
      }
    },
    isIRLSession: {
      handler(val) {
        if (val) {
          this.working = false
          this.checkLabel = "Take your game photo"
          this.isCameraWorking = true
        }
      },
      immediate: true
    }
  },

  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId)
    }
    if (this.bufferInterval) {
      clearInterval(this.bufferInterval)
    }
  },

  methods: {
    async onCountdownEnd() {
      this.captureImage(await this.imageFromElement(this.videoRef))
    },
    onRetry() {
      this.retry = false
      this.$refs.camera?.start()
    },
    openAvatarUploader() {
      if (this.user.image) {
        this.setStep(2)
        this.setTitle("microphone check")
      } else {
        this.setTitle("your avatar")
        this.isCameraWorking = true
      }
    },

    uploadPhoto() {
      this.file = null
      this.$refs.file.value = ""
      this.$refs.file.click()
    },

    makePhoto() {
      this.counting = true
      this.isWaitingForPhoto = true
      this.checkLabel = "Taking your photo in... "
    },

    async previewFile(event) {
      const [file] = event.target.files
      const mb = file.size / 1024 / 1024
      if (mb > 10) return this.$toast("The image is too big. Try compressing.")

      let img = new Image()
      img.onload = async () => {
        this.captureImage(await this.imageFromElement(img, false))
        img = null
      }
      img.src = URL.createObjectURL(file)
    },

    captureImage(file) {
      this.file = file

      this.counting = false
      this.checkLabel = "Is the image OK?"
      this.isAvatar = true
      this.isWaitingForPhoto = false
    },

    resetAvatar() {
      this.file = null
      this.checkLabel = "Take or upload your game photo"
      this.isAvatar = false
    },

    async goToAudioCheck() {
      let image

      try {
        const blob = this.file
        const fileName = `gamephotos/${uniqid()}-${this.user?.username}.jpg`
        this.startBuffer()
        image = await uploadMedia({
          fileName,
          blob,
          token: this.token
        })
      } catch (e) {
        console.error(e)
        this.$toast(
          "It looks like you are behind a firewall or VPN and therefore can't save your photo."
        )
        image = "https://jaznau.com/wp-content/uploads/2019/08/vpn.jpg"
      } finally {
        this.uploading = false
      }

      await this.$store.dispatch("updateUser", {
        userID: this.user.id,
        obj: { image }
      })

      if (this.isIRLSession) {
        this.$store.commit("auth/UPDATE_LAST_KNOWN_ONBOARDING", true)
        return
      }

      this.setStep(2)
      this.setTitle("microphone check")
    },

    showHelpMessage() {
      this.$snackbar({
        message: `Sorry, please try
        <a target="_blank" href="https://www.weve.co/support" >
          webcam support
        </a>
        and refresh your browser.`,
        variant: "warning",
        timeout: 7000
      })
    },
    startBuffer() {
      this.checkLabel = "Uploading..."
      this.uploading = true
    }
  }
}
</script>

<style lang="scss" scoped>
.hidden {
  opacity: 0;
  pointer-events: none;
}
</style>
