var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "audio-video-setup" },
    [
      _vm.step === 1
        ? _c("VideoCard", {
            attrs: { setTitle: _vm.setTitle, setStep: _vm.setStep },
          })
        : _vm.step === 2
        ? [
            !_vm.isMicWorking
              ? _c("AudioCard", { on: { microphoneOK: _vm.microphoneOK } })
              : _c("SoundCheck", {
                  attrs: { setTitle: _vm.setTitle, setStep: _vm.setStep },
                }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }