var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.isIRLSession
        ? _c("p", { staticClass: "setup-sublabel" }, [
            _vm._v("Your active camera"),
          ])
        : _vm._e(),
      _vm.canShowVideo && !_vm.isIRLSession
        ? _c("Camera", {
            ref: "camera",
            attrs: {
              image: !!_vm.file,
              camera: _vm.camera,
              cameras: _vm.cameras,
            },
            on: {
              ready: function ($event) {
                _vm.videoRef = $event
              },
              error: function (e) {
                return (_vm.retry = !!e)
              },
              working: function (e) {
                return (_vm.working = !!e)
              },
              onCameraChanged: function ($event) {
                _vm.file = null
              },
            },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "row justify-center mt-3" },
        [
          _vm.isIRLSession
            ? _c("CanvasImage", { attrs: { width: "220", height: "220" } })
            : _vm._e(),
        ],
        1
      ),
      _vm.retry
        ? [
            _c("div", { staticClass: "setup-check-label" }, [
              _vm._v("Try again?"),
            ]),
            _c("div", { staticClass: "control-buttons" }, [
              _c(
                "button",
                {
                  staticClass: "weve-btn weve-btn--default",
                  on: {
                    click: function ($event) {
                      return _vm.onRetry()
                    },
                  },
                },
                [_vm._v(" Yes ")]
              ),
            ]),
          ]
        : [
            _c(
              "div",
              {
                staticClass: "setup-check-label",
                class: _vm.working ? "hidden" : null,
              },
              [
                _vm._v(" " + _vm._s(_vm.checkLabel) + " "),
                _vm.counting
                  ? _c("VueCountdown", {
                      attrs: { time: 3000 },
                      on: { end: _vm.onCountdownEnd },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var totalSeconds = ref.totalSeconds
                              return [
                                _vm._v("   " + _vm._s(totalSeconds) + " "),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1583461425
                      ),
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "control-buttons",
                class: _vm.working ? "hidden" : null,
              },
              [
                !_vm.isCameraWorking
                  ? [
                      _c(
                        "button",
                        {
                          staticClass: "weve-btn weve-btn--outline",
                          on: { click: _vm.showHelpMessage },
                        },
                        [_vm._v(" No ")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "weve-btn weve-btn--default",
                          on: { click: _vm.openAvatarUploader },
                        },
                        [_vm._v(" Yes ")]
                      ),
                    ]
                  : _vm._e(),
                !_vm.isAvatar && _vm.isCameraWorking
                  ? [
                      _c(
                        "button",
                        {
                          staticClass: "weve-btn weve-btn--outline",
                          class: { "weve-btn--hidden": _vm.isWaitingForPhoto },
                          attrs: { disabled: _vm.isWaitingForPhoto },
                          on: { click: _vm.uploadPhoto },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.isIRLSession ? "Take Photo" : "Upload"
                              ) +
                              " "
                          ),
                        ]
                      ),
                      !_vm.isIRLSession
                        ? _c(
                            "button",
                            {
                              staticClass: "weve-btn weve-btn--default",
                              class: {
                                "weve-btn--hidden": _vm.isWaitingForPhoto,
                              },
                              attrs: { disabled: _vm.isWaitingForPhoto },
                              on: { click: _vm.makePhoto },
                            },
                            [_vm._v(" Take Photo ")]
                          )
                        : _vm._e(),
                    ]
                  : _vm._e(),
                _vm.isAvatar
                  ? [
                      _c(
                        "button",
                        {
                          staticClass: "weve-btn weve-btn--outline",
                          class: { "weve-btn--hidden": _vm.uploading },
                          on: { click: _vm.resetAvatar },
                        },
                        [_vm._v(" No ")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "weve-btn weve-btn--default",
                          class: { "weve-btn--hidden": _vm.uploading },
                          on: { click: _vm.goToAudioCheck },
                        },
                        [_vm._v(" Yes ")]
                      ),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ],
      _c("input", {
        ref: "file",
        staticStyle: { display: "none" },
        attrs: { type: "file", accept: "image/x-png,image/gif,image/jpeg" },
        on: { change: _vm.previewFile },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }