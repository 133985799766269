var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sound-check" },
    [
      _c("div", { staticClass: "sound-check__container" }, [
        _vm.paused
          ? _c("img", { attrs: { src: _vm.vinylImg } })
          : _c("img", { attrs: { src: _vm.vinylPlayImg } }),
        _vm.paused
          ? _c("img", {
              staticClass: "sound-check__play-btn",
              attrs: { src: _vm.playImg },
              on: { click: _vm.play },
            })
          : _vm._e(),
      ]),
      _vm.paused
        ? _c("div", { staticClass: "setup-check-label sound-check-label" }, [
            _vm._v(" " + _vm._s(_vm.checkLabel) + " "),
          ])
        : _c("div", { staticClass: "setup-check-label audio-check-label" }, [
            _vm._v(" Can you hear the music? "),
            _c("br"),
            _vm._v(" If you do, you’re ready to join! "),
          ]),
      !_vm.paused
        ? _c(
            "div",
            { staticClass: "control-buttons sound-check__sound-ok-btns" },
            [
              _c(
                "button",
                {
                  staticClass:
                    "weve-btn weve-btn--outline weve-btn--responsive",
                  on: { click: _vm.showHelpMessage },
                },
                [_vm._v(" I don’t hear it ")]
              ),
              _c(
                "button",
                {
                  staticClass:
                    "weve-btn weve-btn--default weve-btn--responsive",
                  on: { click: _vm.next },
                },
                [_vm._v(" Yes, Let’s rock! ")]
              ),
            ]
          )
        : _vm._e(),
      _c("SmartMedia", {
        attrs: { src: _vm.src, paused: _vm.paused, volume: 1, looped: "true" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }