var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("p", { staticClass: "setup-sublabel" }, [
        _vm._v("Your microphone device"),
      ]),
      _c("Microphone", { attrs: { isOnboarding: true } }),
      _c("div", { staticClass: "setup-check-label audio-check-label" }, [
        _vm._v(_vm._s(_vm.checkLabel)),
      ]),
      _c("div", { staticClass: "control-buttons" }, [
        _c(
          "button",
          {
            staticClass: "weve-btn weve-btn--outline weve-btn--responsive",
            on: { click: _vm.showHelpMessage },
          },
          [_vm._v(" Not working ")]
        ),
        _c(
          "button",
          {
            staticClass: "weve-btn weve-btn--default weve-btn--responsive",
            on: {
              click: function ($event) {
                return _vm.$emit("microphoneOK")
              },
            },
          },
          [_vm._v(" Yes, it’s working ")]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }