<template>
  <div class="sound-check">
    <div class="sound-check__container">
      <img :src="vinylImg" v-if="paused" />
      <img :src="vinylPlayImg" v-else />
      <img
        class="sound-check__play-btn"
        v-if="paused"
        @click="play"
        :src="playImg"
      />
    </div>

    <div class="setup-check-label sound-check-label" v-if="paused">
      {{ checkLabel }}
    </div>
    <div class="setup-check-label audio-check-label" v-else>
      Can you hear the music? <br />
      If you do, you’re ready to join!
    </div>

    <div class="control-buttons sound-check__sound-ok-btns" v-if="!paused">
      <button
        class="weve-btn weve-btn--outline weve-btn--responsive"
        @click="showHelpMessage"
      >
        I don’t hear it
      </button>
      <button
        class="weve-btn weve-btn--default weve-btn--responsive"
        @click="next"
      >
        Yes, Let’s rock!
      </button>
    </div>

    <SmartMedia :src="src" :paused="paused" :volume="1" looped="true" />
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import SmartMedia from "@/modules/smart-media/components/SmartMedia"
import { Misc as DictionaryMisc } from "@/dictionary"

const VINYL_IMG = require("@/assets/login/vinyl-plate.svg")
const VINYL_PLAY_IMG = require("@/assets/login/vinyl-plate-play.svg")
const PLAY_IMG = require("@/assets/login/play.svg")
const TEST_AUDIO_URL = require("@/assets/celebration.mp3")

export default {
  name: "SoundCheck",
  props: ["setTitle", "setStep"],
  components: {
    SmartMedia
  },
  data() {
    return {
      vinylImg: VINYL_IMG,
      vinylPlayImg: VINYL_PLAY_IMG,
      playImg: PLAY_IMG,
      checkLabel: DictionaryMisc.CHECK_SOUND_LABEL,
      src: TEST_AUDIO_URL,
      paused: true
    }
  },
  computed: {
    ...mapGetters(["game"]),
    ...mapGetters("auth", ["hasPreGame", "isHost", "client"])
  },
  mounted() {
    this.setTitle("Sound check")
  },
  methods: {
    isLocked() {
      return (
        !this.isHost &&
        ((this.hasPreGame && !!this.client?.locked) || !!this.game?.locked)
      )
    },
    play() {
      this.paused = false
    },
    next() {
      if (this.isLocked()) this.setStep(3)
      this.$store.commit("auth/UPDATE_LAST_KNOWN_ONBOARDING", true)
    },
    showHelpMessage() {
      this.$snackbar({
        message: DictionaryMisc.WE_CANT_DETECT_ANY_ISSUES,
        variant: "warning",
        timeout: 7000,
        offset: 40
      })
    }
  }
}
</script>

<style lang="scss">
.sound-check {
  &__container {
    position: relative;
    max-width: 25vh;
    margin: 5vh auto 0;

    img {
      width: 100%;
      height: auto;
    }
  }

  &__play-btn {
    width: 66px !important;
    height: 66px !important;
    position: absolute;
    bottom: -33px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
  }

  &__sound-ok-btns {
    margin-top: 17px;
  }
}
</style>
