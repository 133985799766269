<template>
  <div>
    <p class="setup-sublabel">Your microphone device</p>
    <Microphone :isOnboarding="true" />
    <div class="setup-check-label audio-check-label">{{ checkLabel }}</div>
    <div class="control-buttons">
      <button
        class="weve-btn weve-btn--outline weve-btn--responsive"
        @click="showHelpMessage"
      >
        Not working
      </button>
      <button
        class="weve-btn weve-btn--default weve-btn--responsive"
        @click="$emit('microphoneOK')"
      >
        Yes, it’s working
      </button>
    </div>
  </div>
</template>

<script>
import Microphone from "@/components/GroupTeams/Common/AudioVideoSettings/Microphone"
import { Misc as DictionaryMisc } from "@/dictionary"

export default {
  name: "AudioCard",
  components: {
    Microphone
  },
  data() {
    return {
      checkLabel: DictionaryMisc.CHECK_MIC_LABEL
    }
  },
  methods: {
    showHelpMessage() {
      this.$snackbar({
        message: DictionaryMisc.WE_CANT_DETECT_ANY_ISSUES,
        variant: "warning",
        timeout: 7000,
        offset: 40
      })
    }
  }
}
</script>
